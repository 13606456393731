import React from "react";
import Method from "./method";
import methodPicture1 from "../../../images/components/main/methodology/method/methodPicture1.svg";
import methodPicture2 from "../../../images/components/main/methodology/method/methodPicture2.svg";
import Advantages from "./advantages";

function Methodology() {
  return (
    <div className="wrap">
      <section className="methodology">
        <h2>Наша методика обучения</h2>
        <Method
          heading="Как наши эмоции влияют на формирование памяти."
          text={
            <>
              <p>
                Эмоции стимулируют выработку нейромедиаторов, таких как дофамин,
                серотонин и ацетилхолин, которые способствуют укреплению памяти.
                Когда мы испытываем сильную эмоцию, наш мозг создает новую связь
                между нейронами, укрепляя память обэтом событии. Этот процесс
                известен как синаптическая пластичность.
              </p>
              <p> Поэтому мы долго помним смешные шутки или как нас обидели.</p>
            </>
          }
          imgSrc={methodPicture1}
        />
        <Method
          heading="Роль мышечной памяти в обучении."
          text={
            <p>
              Когда мы говорим на родном языке, у нас обычно в голове пусто и мы
              не задумываемся о том, как нужно построить мысль. Это наша цель!
              Чтобы снижать давление с головы мы проделываем многочисленные
              упражнения на мышечную память, после которых ваше тело будет
              физически чувствовать что вы сделали ошибку"
            </p>
          }
          isLeft={false}
          imgSrc={methodPicture2}
        />
        <Advantages />
      </section>
    </div>
  );
}

export default Methodology;

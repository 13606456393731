import { RefObject, useCallback, useEffect } from "react";

interface ScrollToOptions {
  behavior?: ScrollBehavior;
}

interface ScrollTarget {
  ref: RefObject<HTMLElement | null>;
  name: string;
}

const useScrollToElements = (
  targets: ScrollTarget[],
): { [key: string]: (options?: ScrollToOptions) => void } => {
  useEffect(() => {}, [targets]);

  const scrollFunctions: {
    [key: string]: (options?: ScrollToOptions) => void;
  } = {};

  targets.forEach((target) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    scrollFunctions[target.name] = useCallback(() => {
      if (target.ref.current) {
        target.ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [target.ref]);
  });

  return scrollFunctions;
};

export default useScrollToElements;

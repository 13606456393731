import React from "react";
import Review from "./review";

import reviewPhoto1 from "../../../images/components/main/reviews/reviewsPhoto1.png";
import reviewPhoto2 from "../../../images/components/main/reviews/reviewsPhoto2.png";
import reviewPhoto3 from "../../../images/components/main/reviews/reviewsPhoto3.png";
import reviewPhoto4 from "../../../images/components/main/reviews/reviewsPhoto4.png";
import reviewPhoto5 from "../../../images/components/main/reviews/reviewsPhoto5.png";
import reviewPhoto6 from "../../../images/components/main/reviews/reviewsPhoto6.png";

function Reviews() {
  return (
    <div className="wrap">
      <section className="reviews">
        <h2>Отзывы наших учеников</h2>
        <div className="reviews__inner">
          <Review
            text={
              <p>
                Самые лучшие учителя! Уроки проходят интересно, комфортно, а
                самое главное -продуктивно!! В первый раз в жизни чувствую
                прогресс в изучении английского! Очень удобно, что все проходит
                интерактивно, не нужны никакие блокнотики или тетрадки, как
                говорится- современно
              </p>
            }
            imgSrc={reviewPhoto1}
            name="Ангелина"
          />
          <Review
            text={
              <p>
                Современный подход к обучению. Занятия проходят интересно, не
                занудно, а главное запоминается обсуждаемый материал. Спасибо!
              </p>
            }
            imgSrc={reviewPhoto2}
            name="Глеб"
          />
          <Review
            text={
              <p>
                Понятные темы. Понятное объяснение. Легкое общение с
                преподавателем без упреков. Заниматься комфортно!
              </p>
            }
            imgSrc={reviewPhoto3}
            name="Никита"
          />
          <Review
            text={
              <p>
                Преподаватель сразу понял сложный запрос, которые не могли
                понять другие преподователи попробовали, и действительно
                методики пошли хорошо эффективно набираю лексический запас слов
              </p>
            }
            imgSrc={reviewPhoto4}
            name="Артем"
          />
          <Review
            text={
              <p>
                Дмитрий, без приувеличения, самый лучший мой учитель английского
                языка! Спасибо вам за все "interesting", "exciting" и "awesome"
                моменты на наших уроках. Вы действительно делаете мир лучше, а
                английский — понятнее.
              </p>
            }
            imgSrc={reviewPhoto5}
            name="Анна"
          />
          <Review
            text={
              <p>
                Занимался с Дмитрием - отличный преподаватель, интересная подача
                материала, а также индивидуальный подход.
              </p>
            }
            imgSrc={reviewPhoto6}
            name="Кирилл"
          />
        </div>
      </section>
    </div>
  );
}

export default Reviews;

import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

interface sliderProps {
  slides: {
    type: string;
    src: string;
  }[];
  scrollToFormRef: () => void;
}

const ContentSlider = ({ slides, scrollToFormRef }: sliderProps) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    dotsClass: "slick-dots custom-dots",
    centerMode: true,
    centerPadding: "25%",
    draggable: true,
  };

  return (
    <section className="slider-container">
      <h2>Как выглядит процесс обучения</h2>
      <article className="slider-container-slider">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className="slide-item">
              {slide.type === "image" ? (
                <img
                  width="100%"
                  height="100%"
                  src={slide.src}
                  alt={`Slide ${index}`}
                />
              ) : (
                <iframe
                  width="100%"
                  height="100%"
                  src={`${slide.src}?autoplay=0&rel=0`}
                  allowFullScreen
                  title={`Video ${index}`}
                />
              )}
            </div>
          ))}
        </Slider>
      </article>
      <button className="slider-container-btn" onClick={scrollToFormRef}>
        Начать учиться
      </button>
    </section>
  );
};

export default ContentSlider;

import React, { ReactNode } from "react";

interface MethodProps {
  text: ReactNode;
  imgSrc: string;
  name: string;
}
const Review: React.FC<MethodProps> = ({ text, imgSrc, name }) => {
  return (
    <article className="review">
      <div className="review__top">
        {imgSrc && <img src={imgSrc} alt="teacher" />}
        <h4 className="review__top-heading">{name}</h4>
      </div>
      <div className="review__bottom">{text}</div>
    </article>
  );
};

export default Review;

import React from "react";
import Tariff from "./tariff";
import trafficIcon1 from "../../../images/components/main/tarffis/tariff1.png";
import trafficIcon2 from "../../../images/components/main/tarffis/tariff2.png";
import trafficIcon3 from "../../../images/components/main/tarffis/tariff3.png";

interface TariffsProps {
  scrollToFormRef: () => void;
}

function Tariffs({ scrollToFormRef }: TariffsProps) {
  return (
    <div className="wrap">
      <section className="tariffs">
        <h2>Тарифы</h2>
        <div className="tariffs__inner">
          <Tariff
            scrollToFormRef={scrollToFormRef}
            heading="Solo / Соло"
            imgSrc={trafficIcon1}
            price={1450}
            text="На индивидуальных уроках у учителя больше времени чтобы заметить ваши ошибки и сразу их исправлять"
          />
          <Tariff
            scrollToFormRef={scrollToFormRef}
            heading="Duo / Дуо"
            imgSrc={trafficIcon2}
            price={950}
            text="На парных уроках у вас есть возможность больше слышать иностранную речь и закреплять фразы еще эффективнее :)"
          />
          <Tariff
            scrollToFormRef={scrollToFormRef}
            heading="Party / Пати"
            imgSrc={trafficIcon3}
            price={650}
            text="На групповых занятиях у вас есть возможность познакомиться с единомышленниками, быть в центре внимания или «затеряться» на фоне, если вы не чувствуете себя уверенно"
          />
        </div>
      </section>
    </div>
  );
}

export default Tariffs;

import React from "react";

interface MethodProps {
  heading: string;
  imgSrc: string;
}
const Language: React.FC<MethodProps> = ({ heading, imgSrc }) => {
  return (
    <article className="language__item">
      {imgSrc && <img src={imgSrc} alt={heading} width="70%" />}
      <p className="language__item__heading">{heading}</p>
    </article>
  );
};

export default Language;

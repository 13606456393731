import React, { ReactNode } from "react";

interface MethodProps {
  name: string;
  text: ReactNode;
  imgSrc: string;
}
const Teacher: React.FC<MethodProps> = ({ name, text, imgSrc }) => {
  return (
    <article className="teacher">
      {imgSrc && <img src={imgSrc} alt="teacher" />}
      <div className="teacher__block">
        <h3 className="teacher__block-name">{name}</h3>
        <div className="teacher__block-text">{text}</div>
      </div>
    </article>
  );
};

export default Teacher;

import React from "react";
import GroupItem from "./groupItem";

import groupPicture1 from "../../../images/components/main/groups/groupPicture1.png";
import groupPicture2 from "../../../images/components/main/groups/groupPicture2.png";
import groupPicture3 from "../../../images/components/main/groups/groupPicture3.png";
import groupPicture4 from "../../../images/components/main/groups/groupPicture4.png";

interface GroupsProps {
  scrollToFormRef: () => void;
}

function Groups({ scrollToFormRef }: GroupsProps) {
  return (
    <div className="wrap">
      <section className="groups">
        <p className="groups__top-text">И много других классных ребят!</p>
        <p className="groups__sub-text">
          Каждые выходные проходят разговорные клубы с носителями :)
        </p>
        <div className="groups__left-right">
          <div className="groups__left">
            <h2 className="groups__left__top-text">Не время сомневаться!</h2>
            <h2 className="groups__left__sub-text">
              Действуй: изучай языки, чтобы расширить свои возможности и открыть
              для себя новый мир!
            </h2>
            <button className="groups__left__btn" onClick={scrollToFormRef}>
              Начать учиться
            </button>
          </div>
          <div className="groups__right">
            <GroupItem
              imgSrc={groupPicture1}
              heading="Избавиться от
страха говорить"
            />
            <GroupItem
              imgSrc={groupPicture2}
              heading="Cоздавать речь
не задумываясь"
            />
            <GroupItem
              imgSrc={groupPicture3}
              heading="Усвоить грамматику
навсегда"
            />
            <GroupItem
              imgSrc={groupPicture4}
              heading="Погрузиться в среду
без путешествий"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Groups;

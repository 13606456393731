import React from "react";
import map from "../../images/components/main/intro/map.svg";

interface IntroProps {
  scrollToFormRef: () => void;
}

function Intro({ scrollToFormRef }: IntroProps) {
  return (
    <div className="wrap">
      <section className="intro">
        <article className="intro-left">
          <h2 className="intro-left__heading">
            Иностранный язык— путь к успеху, знаниям, новым друзьям и ярким
            приключениям!
          </h2>
          <button className="intro-left__btn" onClick={scrollToFormRef}>
            Начать учиться
          </button>
        </article>
        <img
          src={map}
          alt="map"
          className="intro-right"
          draggable="false"
          style={{ pointerEvents: "none" }}
        />
      </section>
    </div>
  );
}

export default Intro;

import React from "react";

import Language from "../sectionLanguage/language";
import englishIcon from "../../../images/components/main/selectionLanguage/english.svg";
import russiaIcon from "../../../images/components/main/selectionLanguage/russia.svg";
import spainIcon from "../../../images/components/main/selectionLanguage/spain.svg";
import chinaIcon from "../../../images/components/main/selectionLanguage/china.svg";

function SelectionLanguage() {
  return (
    <div className="wrap">
      <section className="language">
        <h2>Научим вас говорить грамотно</h2>
        <div className="language__block">
          <Language heading="Английский" imgSrc={englishIcon} />
          <Language heading="Русский" imgSrc={russiaIcon} />
          <Language heading="Испанский" imgSrc={spainIcon} />
          <Language heading="Китайский" imgSrc={chinaIcon} />
        </div>
      </section>
    </div>
  );
}

export default SelectionLanguage;

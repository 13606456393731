import React from "react";

interface VideoProps {}
const Videos: React.FC<VideoProps> = () => {
  return (
    <div className="wrap">
      <div className="videos">
        <h2>Как говорят наши ученики после обучения</h2>
        <div className="videos__inner">
          <div className="videos__inner-video">
            <iframe
              width="100%"
              height="100%"
              src={`${"https://www.youtube.com/embed/Cze5A4iyQGk"}?autoplay=0&rel=0`}
            />
          </div>
          <div className="videos__inner-video">
            <iframe
              width="100%"
              height="100%"
              src={`${"https://www.youtube.com/embed/tyjvZ6Y3SBE"}?autoplay=0&rel=0`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;

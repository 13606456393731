import React from "react";
import dmitryPhoto from "../../../images/components/main/teachers/dmitryPhoto.svg";
import taisiaPhoto from "../../../images/components/main/teachers/taisiaPhoto.svg";
import ivanPhoto from "../../../images/components/main/teachers/ivanPhoto.svg";
import Teacher from "../teachers/teacher";

function Teachers() {
  return (
    <div className="wrap">
      <section className="teachers">
        <h2>Преподаватели New Vibes</h2>
        <Teacher
          name="Дмитрий Кяхрин"
          text="Учился на факультете биоинженерии и биоинформатики. Создал курс по английскому языку, используя методику мышечного запоминания и актерского мастерства. Я очень люблю биологию и внедряю знания о функционировании нашей памяти в построение уроков, что позволило мне выучить испанский язык за 6 месяцев. В среднем студенты переходят на следующий уровень языка за полгода :) Работал в испаноязычной компании, а также учился на full stack разработчика. Больше года специализируюсь на подготовке к собеседованию в зарубежные айти компании."
          imgSrc={dmitryPhoto}
        />
        <Teacher
          name="Таисия Смирнова"
          text="Репетитор по английскому языку с опытом преподавания более 5 лет. За это время успешно работала с дошкольниками, школьниками и взрослыми учениками. Получает высшее педагогическое образование по направлению «Английский язык» в НГПУ им. Минина. Для того, чтобы быть лучшей в своей сфере, прошла курс «Как обучать онлайн» и приложила свой сертификат."
          imgSrc={taisiaPhoto}
        />
        <Teacher
          name="Ивaн Андреев"
          text={
            <>
              <p>
                Студент 4 курca пeдaгoгичecкого унивеpcитeта по нaпpавлeнию
                aнглийский и нeмeцкий языки
              </p>
              <ul>
                <li>— Мой уровень английского B2</li>
                <li>— Сдал EГЭ пo английскoму нa 91 бaлл</li>
                <li>
                  — Имeю опыт рaботы как в языкoвoй школe, тaк и в
                  oнлaйн-фoрмате.
                </li>
              </ul>
            </>
          }
          imgSrc={ivanPhoto}
        ></Teacher>
      </section>
    </div>
  );
}

export default Teachers;

import React from "react";

interface MethodProps {
  heading: string;
  imgSrc: string;
}
const GroupItem: React.FC<MethodProps> = ({ heading, imgSrc }) => {
  return (
    <article className="groupItem">
      {imgSrc && <img src={imgSrc} alt="picture" />}
      <p>
        <span>#</span>
        {heading}
      </p>
    </article>
  );
};

export default GroupItem;

import React, { ReactNode } from "react";

interface MethodProps {
  heading: string;
  text: ReactNode;
  imgSrc: string;
  isLeft?: boolean;
}

const Method: React.FC<MethodProps> = ({
  heading,
  text,
  imgSrc,
  isLeft = true,
}) => {
  return (
    <article
      className="method__item"
      style={{ justifyContent: isLeft ? "left" : "right" }}
    >
      {isLeft ? <img src={imgSrc} alt={heading} /> : null}
      <div className="method__text">
        <h3 className="method__text-heading">{heading}</h3>
        <div className="method__text-text">{text}</div>
      </div>
      {!isLeft ? <img src={imgSrc} alt={heading} /> : null}
    </article>
  );
};

export default Method;

import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { withMask } from "use-mask-input";

interface FormData {
  name: string;
  phone: string;
  email: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Имя обязательно")
    .min(3, "Имя должно содержать минимум 3 символа"),
  phone: yup
    .string()
    .required("Телефон обязателен")
    .matches(/^\+7 \(\d{3}\) \d{3} \d{2}-\d{2}$/, "Не верный формат телефона"),
  email: yup
    .string()
    .required("Email обязателен")
    .email("Неверный формат почты"),
});

function Form() {
  const onSubmit = (inputData: FormData) => {
    console.log(inputData);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  return (
    <div className="startStudy">
      <section className="startStudy__inner">
        <div></div>
        <form
          action=""
          className="startStudy__inner__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="startStudy__inner__form__top">
            <fieldset>
              <Controller
                name="name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <input
                    className={errors.name?.message ? "error" : ""}
                    id="name"
                    type="text"
                    placeholder="Имя"
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <span className="form__inner__group__error">
                  {errors.name?.message}
                </span>
              )}
            </fieldset>
            <fieldset>
              <Controller
                name="phone"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className={errors.name?.message ? "error" : ""}
                    id="phone"
                    type="text"
                    placeholder="Номер телефона"
                    ref={withMask("+7 (999) 999 99-99")}
                  />
                )}
              />
              {errors.phone && (
                <span className="form__inner__group__error">
                  {errors.phone?.message}
                </span>
              )}
            </fieldset>
            <fieldset>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <input
                    className={errors.email?.message ? "error" : ""}
                    id="email"
                    type="text"
                    placeholder="Email"
                    {...field}
                  />
                )}
              />
              {errors.name && (
                <span className="form__inner__group__error">
                  {errors.email?.message}
                </span>
              )}
            </fieldset>
            <p>
              Оставляя заявку вы соглашаетесь на сбор и обработку персональных
              данных
            </p>
          </div>
          <button>Начать учиться</button>
        </form>
      </section>
    </div>
  );
}

export default Form;

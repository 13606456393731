import React from "react";

import rowIcon from "../../../images/components/main/methodology/advantages/rowIcon.svg";
import clockIcon from "../../../images/components/main/methodology/advantages/clockIcon.svg";
import progressMeIcon from "../../../images/components/main/methodology/advantages/progressMeIcon.svg";

function Advantages() {
  return (
    <section className="advantages">
      <article className="advantages__lesson">
        <img src={progressMeIcon} alt="logo progress me" />
        <p>
          Урок проходит онлайн в{" "}
          <a className="advantages__lesson-link" href="/">
            ProgressMe
          </a>
        </p>
      </article>
      <img src={rowIcon} alt="row" className="row" />
      <article className="advantages__duration">
        <img src={clockIcon} alt="Icon clock" />
        <p>Продолжительность занятия 50 минут</p>
      </article>
    </section>
  );
}

export default Advantages;

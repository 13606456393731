import React, { RefObject, useRef } from "react";
import Intro from "../components/main/intro";
import slider_img_1_Icon from "../images/components/main/slider/slider_img_1.jpg";
import slider_img_2_Icon from "../images/components/main/slider/slider_img_2.jpg";
import slider_img_3_Icon from "../images/components/main/slider/slider_img_3.jpg";
import slider_img_4_Icon from "../images/components/main/slider/slider_img_4.jpg";
import slider_img_5_Icon from "../images/components/main/slider/slider_img_5.jpg";
import slider_img_6_Icon from "../images/components/main/slider/slider_img_6.jpg";
import slider_img_7_Icon from "../images/components/main/slider/slider_img_7.jpg";
import useScrollToElements from "../hooks/useScrollToElement";
import SelectionLanguage from "../components/main/sectionLanguage/selectionLanguage";
import Teachers from "../components/main/teachers/teachers";
import Groups from "../components/main/groups/groups";
import Videos from "../components/main/videos";
import ContentSlider from "../components/main/slider";
import Tariffs from "../components/main/tariffs/tariffs";
import Reviews from "../components/main/reviews/reviews";
import Methodology from "../components/main/methodology/methodology";
import Form from "../components/main/form";

interface MainProps {
  howStudyRef: RefObject<HTMLDivElement | null>;
  tariffsRef: RefObject<HTMLDivElement | null>;
  reviewsRef: RefObject<HTMLDivElement | null>;
}

function Main({ howStudyRef, tariffsRef, reviewsRef }: MainProps) {
  const formRef = useRef<HTMLDivElement>(null);
  const scrollFunctions = useScrollToElements([
    { ref: formRef, name: "scrollToFormRef" },
  ]);

  const slides = [
    {
      type: "video",
      src: "https://www.youtube.com/embed/Sj19h9wBbN0",
    },
    {
      type: "image",
      src: slider_img_1_Icon,
    },
    {
      type: "image",
      src: slider_img_2_Icon,
    },
    {
      type: "image",
      src: slider_img_3_Icon,
    },
    {
      type: "image",
      src: slider_img_4_Icon,
    },
    {
      type: "image",
      src: slider_img_5_Icon,
    },
    {
      type: "image",
      src: slider_img_6_Icon,
    },
    {
      type: "image",
      src: slider_img_7_Icon,
    },
  ];

  return (
    <>
      <Intro scrollToFormRef={() => scrollFunctions.scrollToFormRef()} />
      <SelectionLanguage />
      <div ref={howStudyRef}>
        <Methodology />
      </div>
      <Teachers />
      <Groups scrollToFormRef={() => scrollFunctions.scrollToFormRef()} />
      <Videos />
      <ContentSlider
        slides={slides}
        scrollToFormRef={() => scrollFunctions.scrollToFormRef()}
      />
      <div ref={tariffsRef}>
        <Tariffs scrollToFormRef={() => scrollFunctions.scrollToFormRef()} />
      </div>
      <div ref={reviewsRef}>
        <Reviews />
      </div>
      <div ref={formRef}>
        <Form />
      </div>
    </>
  );
}

export default Main;

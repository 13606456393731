import React, { FC } from "react";

interface ITariff {
  heading: string;
  imgSrc: string;
  price: number;
  text: string;
  scrollToFormRef: () => void;
}

const Tariff: FC<ITariff> = ({
  heading,
  imgSrc,
  price,
  text,
  scrollToFormRef,
}) => {
  return (
    <article className="tariff">
      <h3 className="tariff__heading">{heading}</h3>
      <img className="tariff__image" src={imgSrc} alt="tariff" />
      <p className="tariff__image-text">{price} рублей / час</p>
      <div className="tariff__bottom">
        <p className="tariff__text">{text}</p>
        <button className="tariff__button" onClick={scrollToFormRef}>
          Записаться
        </button>
      </div>
    </article>
  );
};

export default Tariff;

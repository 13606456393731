import React, { useState } from "react";

import "../../styles/components/layouts/header.scss";
import logo from "../../images/components/layout/header/logo.svg";
import telegramIcon from "../../images/components/layout/header/telegrammIcon.svg";
import vkIcon from "../../images/components/layout/header/vkIcon.svg";
import arrowCloseIcon from "../../images/components/layout/header/arrowClose.svg";
import arrowOpenIcon from "../../images/components/layout/header/arrowOpen.svg";

interface HeaderProps {
  scrollToHowStudyRef: () => void;
  scrollToTariffsRef: () => void;
  scrollToReviewsRef: () => void;
}

function Header({
  scrollToHowStudyRef,
  scrollToTariffsRef,
  scrollToReviewsRef,
}: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <header className="header">
      <div className="wrap">
        <div className="header__inner">
          <div className="header__logo__block">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
            <div className="header__logo__block__text">
              <h1 className="header__logo__block-heading">New Vibes</h1>
              <h3 className="header__logo__block-subHeading">
                языковая онлайн школа
              </h3>
            </div>
          </div>
          <div
            className="header__menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            onMouseEnter={() => setIsMenuOpen(true)}
            onMouseLeave={() => setIsMenuOpen(false)}
          >
            <div
              className={`header__menu-visible ${isMenuOpen ? "active" : ""}`}
            >
              <div className="header__menu-visible__chocial">
                <a href="/">
                  <img src={telegramIcon} alt="telegramm" />
                </a>
                <a href="/">
                  <img src={vkIcon} alt="vk" />
                </a>
              </div>
              <div className="header__menu-visible-click">
                <p className="header__menu-click-text">Меню</p>
                <img
                  src={isMenuOpen ? arrowOpenIcon : arrowCloseIcon}
                  alt="arrow"
                />
              </div>
            </div>
            <button
              className="burger"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span></span>
            </button>
            <nav
              className={`header__menu-active ${isMenuOpen ? "active" : ""}`}
            >
              <ul className="header__menu-active__list">
                <li className="header__menu-active__link">
                  <a onClick={scrollToHowStudyRef}>Обучение</a>
                </li>
                <li className="header__menu-active__link">
                  <a onClick={scrollToTariffsRef}>Тарифы</a>
                </li>
                <li className="header__menu-active__link">
                  <a onClick={scrollToReviewsRef}>Отзывы</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

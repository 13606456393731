import React, { useRef } from "react";
import Header from "./components/layout/header";
import Main from "./pages/main";
import useScrollToElements from "./hooks/useScrollToElement";

function App() {
  const howStudyRef = useRef<HTMLDivElement>(null);
  const tariffsRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const scrollFunctions = useScrollToElements([
    { ref: howStudyRef, name: "scrollToHowStudyRef" },
    { ref: tariffsRef, name: "scrollToTariffsRef" },
    { ref: reviewsRef, name: "scrollToReviewsRef" },
  ]);

  return (
    <div className="container">
      <Header
        scrollToHowStudyRef={() => scrollFunctions.scrollToHowStudyRef()}
        scrollToTariffsRef={() => scrollFunctions.scrollToTariffsRef()}
        scrollToReviewsRef={() => scrollFunctions.scrollToReviewsRef()}
      />
      <Main
        howStudyRef={howStudyRef}
        tariffsRef={tariffsRef}
        reviewsRef={reviewsRef}
      />
    </div>
  );
}

export default App;
